// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supervisor-help-modal-content{
    background-color: #221F20;
    color: #FFCC01;
    padding: 2vw;
    box-shadow: 0 0.1vw 0.15vw rgba(0, 0, 0, 0.1);
    z-index: 1001;
}`, "",{"version":3,"sources":["webpack://./src/SupervisorHelpModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,6CAA6C;IAC7C,aAAa;AACjB","sourcesContent":[".supervisor-help-modal-content{\n    background-color: #221F20;\n    color: #FFCC01;\n    padding: 2vw;\n    box-shadow: 0 0.1vw 0.15vw rgba(0, 0, 0, 0.1);\n    z-index: 1001;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
