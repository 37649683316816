// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignEmployee-box {
  background: #FFCC01;
  width: 40vw;
  max-height: 90vh;
  padding: 0;
  text-align: center;
  align-items: center;
  position: relative;
  margin-top: 0.5vh; /* Adjust as needed for spacing from the top */
  max-width: 80%; /* Adjust as needed for the maximum width of the modal */
  overflow: auto;
  pointer-events: auto;
}

.assignEmployee-title {
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 0.5vh;
}`, "",{"version":3,"sources":["webpack://./src/AssignEmployeeModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB,EAAE,8CAA8C;EACjE,cAAc,EAAE,wDAAwD;EACxE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".assignEmployee-box {\n  background: #FFCC01;\n  width: 40vw;\n  max-height: 90vh;\n  padding: 0;\n  text-align: center;\n  align-items: center;\n  position: relative;\n  margin-top: 0.5vh; /* Adjust as needed for spacing from the top */\n  max-width: 80%; /* Adjust as needed for the maximum width of the modal */\n  overflow: auto;\n  pointer-events: auto;\n}\n\n.assignEmployee-title {\n  font-size: 3vw;\n  font-weight: bold;\n  margin-bottom: 0.5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
