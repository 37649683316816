import ProgressBar from 'react-bootstrap/ProgressBar';
import React, { useState} from 'react';
import axios from 'axios';
import './ImportModal.css';

const ImportModal = ({ onClose, userID }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDataLoading, changeDataStatus] = useState(false);
  const [uploadPercentage, updateProgress] = useState(0);

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const importDone = () => {
    changeDataStatus(false)
    updateProgress(0)
    setSelectedFile(null)
  }

  const Spinner = () => {
    return(
      <div>
        <div className="import-spinner">
          {/* <h2>Your data is being loaded, please wait!</h2> */}
          {(uploadPercentage < 100) ? (
          <h2 className="spinner-load">Your data is being loaded, please wait!</h2>) : (
          <h2 className="spinner-load">Your data has been loaded!</h2>
          )}
            {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} /> }
        </div>
        <div>
          {uploadPercentage === 100 && <button className="manhours-confirm-btn supervisor-btn" 
          onClick={importDone}>Back</button>}
        </div>
      </div>
    )
  }

  const RegularImport = () => {

    return(
      <div>
        <p className="font-size2">Import</p>
          <button className="close-btn medium-image supervisor-btn" onClick={onClose}>
            <img src='./img/close-b.svg' alt="Close B" />
          </button>
          <input type="file" className="fileInput" onChange={handleFileSelect} />
          <button onClick={axiosUploadFile} disabled={!selectedFile} className="supervisor-btn small-btn">
            <img src='./img/upload.svg' alt="Upload" title="Upload File"/></button>
          {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} /> }
      </div>
    )
  }

  const axiosUploadFile = ({target: {files}}) => {
    console.log(selectedFile);
    let data = new FormData();
    data.append('file', selectedFile)
    changeDataStatus(true)
    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        console.log( `${loaded}b of ${total}b | ${percent}%`);

        if(percent <= 100){
          updateProgress(percent)
          console.log('state.uploadPercentage is currently at: ' + percent);
        }
      }
    }

    axios.post(`https://www.maintenancesmartcalendar.com/api/service/addTasks/${userID}`, data, options).then(res => {
      console.log(res)
    })
  }

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="small-modal" onClick={e => e.stopPropagation()}>
          {!isDataLoading && <RegularImport />}

          {isDataLoading && <Spinner />}

          {/* <input type="file" onChange={this.uploadFile}/> */}
        </div>
      </div>
    );
}

export default ImportModal;
