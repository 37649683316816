// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-modal-content {
  background-color: #FFCC01;
  padding: 2vw;
  box-shadow: 0 0.1vw 0.15vw rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Above the backdrop */
}

.help-text{
  padding: 0.5vw;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/HelpModal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,6CAA6C;EAC7C,aAAa,EAAE,uBAAuB;AACxC;;AAEA;EACE,cAAc;EACd,SAAS;AACX","sourcesContent":[".help-modal-content {\r\n  background-color: #FFCC01;\r\n  padding: 2vw;\r\n  box-shadow: 0 0.1vw 0.15vw rgba(0, 0, 0, 0.1);\r\n  z-index: 1001; /* Above the backdrop */\r\n}\r\n\r\n.help-text{\r\n  padding: 0.5vw;\r\n  margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
