import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './manager.css';
import './schedule.css';
import ScheduleHelpModal from './SupervisorHelpModal';

function ScheduleViewPage() {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [tasksInTable, setTasksInTable] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekDates, setWeekDates] = useState([]);
  const [selectedDayDate, setSelectedDayDate] = useState(null);
  const [managerName, setManagerName] = useState("");
  const [managerManhours, setManagerManhours] = useState("");
  const [supervisorHelpModalOpen, setSupervisorHelpModalOpen] = useState(false); //help Page

  const { superID, managerID } = useParams();
  

  const returnHome = () => {
    navigate("/SupervisorHome", { state: { superID: superID } });
  }

  // Define a mapping of day abbreviations
  const dayAbbreviations = {
    'Monday': 'MON',
    'Tuesday': 'TUE',
    'Wednesday': 'WED',
    'Thursday': 'THU',
    'Friday': 'FRI'
  };

  /*Adding pages*/

  const toggleScheduleHelpModal = () => {
    setSupervisorHelpModalOpen(!supervisorHelpModalOpen);
  };

  // Function to get rid of the commas taken from the getCurrentDate
  function removeLastLetter(inputString) {
    // Check if the string is not empty
    if (inputString.length > 0) {
      // Use slice to create a new string without the last character
      return inputString.slice(0, -1);
    } else {
      // If the string is empty, return it as is
      return inputString;
    }
  }

  // Function to get the current date in the format: Monday March 4th, 2024
  const getCurrentDate = () => {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    const [weekday, month, day, year] = currentDate.split(' ');
    const newWeekday = removeLastLetter(weekday);
    return `${newWeekday}: ${month} ${parseInt(day, 10)}, ${year}`;
  };

  const handleLeftArrow = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 7); // Move back by 7 days (a week)
      return newDate;
    });
  };

  const handleRightArrow = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 7); // Move forward by 7 days (a week)
      return newDate;
    });
  };

  // Whenever the currentDate is updated, the days of the week update
  useEffect(() => {
    // Define getWeekDates inside useEffect
    const getWeekDates = () => {
      const daysInWeek = [];
      const startDate = new Date(currentDate);
      const currentDay = startDate.getDay();
      const daysUntilMonday = (currentDay === 0 ? 6 : currentDay - 1);
      startDate.setDate(startDate.getDate() - daysUntilMonday);

      for (let i = 0; i < 5; i++) {
        const day = new Date(startDate);
        day.setDate(startDate.getDate() + i);
        const formattedDate = `${day.toLocaleDateString(undefined, { weekday: 'long' })}: ${day.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })}`;
        daysInWeek.push(formattedDate);
      }

      return daysInWeek;
    };

    // Call getWeekDates to get the array of week dates and update the state
    const weekDates = getWeekDates();
    setWeekDates(weekDates);
  }, [currentDate]); // This effect runs whenever currentDate changes

  // Update the Date whenever a Day button is clicked
  const handleDayButtonClick = (selectedDate) => {

    setSelectedDayDate(selectedDate);

    const selectedDateObj = new Date(selectedDate);

    // Filter tasks based on the selected date
    const filteredTasks = tasks.filter(task => {
      // Convert UTC date string to Date object in local time zone
      const taskDateUTC = new Date(task['Set Date']);
      const taskDate = new Date(taskDateUTC.getTime() + taskDateUTC.getTimezoneOffset() * 60000);

      return taskDate.getDate() === selectedDateObj.getDate();
    });

    setTasksInTable(filteredTasks);

  };

  const fetchTasks = useCallback(async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getTasks/${managerID}`, {
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();
        setTasks(result);

        var selectedDateObj;

        if (selectedDayDate === null) {
          selectedDateObj = new Date();
        }
        else {
          selectedDateObj = new Date(selectedDayDate);
        }

        // Filter tasks based on the selected date
        const filteredTasks = tasks.filter(task => {
          // Convert UTC date string to Date object in local time zone
          const taskDateUTC = new Date(task['Set Date']);
          const taskDate = new Date(taskDateUTC.getTime() + taskDateUTC.getTimezoneOffset() * 60000);
          return taskDate.getFullYear() === selectedDateObj.getFullYear() &&
            taskDate.getMonth() === selectedDateObj.getMonth() &&
            taskDate.getDate() === selectedDateObj.getDate()
        });

        setTasksInTable(filteredTasks);
      } else {
        console.error('Failed to fetch tasks.');
        // Handle the error or show an error message to the user
      }
    } catch (error) {
      console.error('Error while fetching tasks:', error);
      // Handle the error or show an error message to the user
    }
  }, [managerID, selectedDayDate]);

  useEffect(() => {
    if (selectedDayDate === null) {
      fetchTasks();
    }
  }, [managerID, currentDate, fetchTasks, selectedDayDate]);

  // Get the managers' info to create the displays
  const getManagers = async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getMan/${superID}`, {
        method: 'GET',
      });

      // If successful
      if (response.ok) {
        const result = await response.json();

        // Filter out the manager with matching managerID
        const manager = result.managers.Items.find(manager => manager.ID === managerID);
        if (manager) {
          setManagerName(manager.name);
          setManagerManhours(manager.manhours);
        }

      } else {

        // Otherwise display an error message
        console.error('Error fetching managers:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching managers:', error.message);
    }
  }

  useEffect(() => {
    if (superID) {
      getManagers();
    }
  }, []);



  return (
    <div className="manager-container">
      <div className="login-header supervisor-colors">
        <div className="leftright-section">
          <div>
            <p className="manager-info font-size2" >Unit Name: {managerName}</p>
            <p className="manager-info font-size1">Manhours: {managerManhours}</p>
          </div>
        </div>
        <div>
          <button className="supervisor-highlight highlight-btn font-size1" onClick={toggleScheduleHelpModal}>Help</button>
        </div>
        <div className="leftright-section right-section">
          <button className="header-btn supervisor-btn" onClick={returnHome}>Back</button>
        </div>
      </div>

      <div className="flex-center margin-top1">
        <div className="week-box-b">
          <button className="arrow-btn supervisor-btn large-image" onClick={handleLeftArrow} title="Previous Week">
            <img src="/img/leftarrow-b.svg" alt="Left Arrow" />
          </button>
          {weekDates.map((date, index) => {
            const [dayName, rest] = date.split(': ');
            const abbreviation = dayAbbreviations[dayName];
            return (
              <button key={index} className="highlight-btn day-btn supervisor-highlight" onClick={() => handleDayButtonClick(date)}>
                <span className="font-size2">{abbreviation}</span> 
                <span className="font-size1">{rest}</span>
              </button>
            );
          })}
          <button className="arrow-btn supervisor-btn large-image" onClick={handleRightArrow} title="Next Week">
            <img src="/img/rightarrow-b.svg" alt="Right Arrow" />
          </button>
        </div>
      </div>
      <p className="login-text">{selectedDayDate || getCurrentDate()}</p> {/* Display the selected date above the day box */}
      <div className="schedule-boxes">
        <div className="day-box">
          <table className="task-table">
            <thead>
              <tr>
                <th className="taskTh supervisor-colors altBorder-supervisor">Admin Number</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Completion Status</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Early Date</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Late Date</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Maintenance Item Description</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Plan Date Maintenance Call</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Description of Technical Object</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Operational Status</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Model Number</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Serial Number</th>
                <th className="taskTh supervisor-colors altBorder-supervisor">Main Work Center</th>
              </tr>
            </thead>
            <tbody>
              {tasksInTable.map((task, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                  <td className="taskTd">
                    <div>{task['Admin Number']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Completion Status']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Early Date']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Late Date']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Maintanence Item Description']}</div>
                  </td>
                  <td className="taskTd"> 
                    <div>{task['Plan Date Maintanence Call']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Description of Technical Object']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Operational Status']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Model Number']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Serial Number']}</div>
                  </td>
                  <td className="taskTd">
                    <div>{task['Main Work Center']}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {supervisorHelpModalOpen && <ScheduleHelpModal onClose={toggleScheduleHelpModal} />}
    </div>
  );
}

export default ScheduleViewPage;
