// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createTasks-modal-content {
  background-color: #FFCC01;
  width: 70vw;
  height: 50vh;
  padding: 1vw;
  border-radius: 3vw;
  box-shadow: 0 0.2vw 0.3vw rgba(0, 0, 0, 0.1);
  overflow-x: auto; 
  overflow-y: auto;
}

.createTask-close-btn{
  position: absolute;
  top: 25vh;
  right: 15vw;
  border: none;
  border-radius: 3vw;
  font-size: 4vw;
  cursor: pointer;
  padding: 0.5vw;
  margin: 1vw;
}

.form-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.5vw;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-right: 0.5vw;
}

.createTasksIn {
  font-size: 1vw;
  padding: 0.5vw;
  padding-block: 0.4vw;
  padding-inline: 0.1vw;
  align-items: center;
  border-width: 0.1vw;
}

.createTaskIn option{
  padding: 0;
}

.short-input{
  width: 8vw;
}

.medium-input{
  width: 14vw;
}

.large-input{
  width: 25vw;
}`, "",{"version":3,"sources":["webpack://./src/CreateTasksModal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,4CAA4C;EAC5C,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,cAAc;EACd,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".createTasks-modal-content {\n  background-color: #FFCC01;\n  width: 70vw;\n  height: 50vh;\n  padding: 1vw;\n  border-radius: 3vw;\n  box-shadow: 0 0.2vw 0.3vw rgba(0, 0, 0, 0.1);\n  overflow-x: auto; \n  overflow-y: auto;\n}\n\n.createTask-close-btn{\n  position: absolute;\n  top: 25vh;\n  right: 15vw;\n  border: none;\n  border-radius: 3vw;\n  font-size: 4vw;\n  cursor: pointer;\n  padding: 0.5vw;\n  margin: 1vw;\n}\n\n.form-row{\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: 0.5vw;\n  align-items: center;\n}\n\n.form-group {\n  display: flex;\n  flex-direction: column;\n  margin-right: 0.5vw;\n}\n\n.createTasksIn {\n  font-size: 1vw;\n  padding: 0.5vw;\n  padding-block: 0.4vw;\n  padding-inline: 0.1vw;\n  align-items: center;\n  border-width: 0.1vw;\n}\n\n.createTaskIn option{\n  padding: 0;\n}\n\n.short-input{\n  width: 8vw;\n}\n\n.medium-input{\n  width: 14vw;\n}\n\n.large-input{\n  width: 25vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
