import React from 'react';
import './HelpModal.css'; // Your CSS file for styling

const HelpModal = ({ onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="help-modal-content" onClick={e => e.stopPropagation()}>
        <div className="font-size1"><p className="help-text">
          This page displays the list of all tasks for the currently selected day. 
          To select a different day, use the left and right arrow buttons to change 
          the week and click on the day you wish to view.
          </p></div>
        <div className="font-size1"><p className="help-text">
          To import a list of tasks, click on the "Import" button. Then click on 
          "Choose File" on the window that pops up and navigate to the file you wish 
          to upload. In order for the upload to be successful, the file MUST be in 
          the .xlsx format. Finally, click on the upload button on the right side. 
          As the file is being read, the progress will be displayed in that window 
          through the progress bar. If your file contains a large number of tasks, 
          this could take upwards of a few minutes to complete, so the smaller the 
          file you import the faster it'll go. Any task that has already been created 
          should not be created a second time. Once it is done, refresh the page 
          and the created tasks for the given day will be displayed in the table.
          </p></div>
        <div className="font-size1"><p className="help-text">
          To create a single task, click on the "+" button on the bottom of the page. 
          A window will pop up with several fields corresponding to the table 
          headings. Fill out these fields and click "Submit" when you are done. 
          It will be added to the currently selected date.
          </p></div>
        <div className="font-size1"><p className="help-text">
          To adjust the number of manhours utilized for a given week, click on 
          "Manhours". Fill in the box in the window that pops up and click "Confirm" 
          to submit the change. You will see the change reflected in the top-left 
          corner of the page.
          </p></div>
        <div className="font-size1"><p className="help-text">
          To manage your employees, click on "Employees". In the window that pops 
          up, you are able to edit most of the information for each employee
          </p></div>
        <div className="font-size1"><p className="help-text">
          You can adjust the details for any task by clicking the spot in the 
          table that contains the detail you want to update. After an update is 
          made, click the button with the checkmark in the "Actions" column on the 
          right side of the table to save it. Click the trashcan button in that same 
          column to delete the task. The last button allows you to change the 
          employees that are assigned to that task.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;
