// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.small-modal {
  background-color: #FFCC01;
  border-radius: 2vw;
  min-width: 20%;
  min-height: 20%;
  padding: 2vw;
  text-align: center;
  position: relative;
  pointer-events: auto;
}

.manhours-input{
  border: 0.15vw solid #221F20;
  font-size: 1vw;
  padding: 0;
  margin: 0;
}

.manhours-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns input vertically */
  padding: .8vw; /* Adjust as needed */
}

.manhours-confirm-btn{
  font-size: 1.5vw;
  border-radius: 0.5vw;
  padding: 0.4vw;
}

.close-btn{
  position: absolute;
  top: 0.5vw;;
  right: 0.5vw;
  border-radius: 3vw;
  padding: 0.2vw;
}`, "",{"version":3,"sources":["webpack://./src/ManhoursModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,cAAc;EACd,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAE,4BAA4B;EACrD,aAAa,EAAE,qBAAqB;AACtC;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".modal-overlay{\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.5);\n  /* Semi-transparent backdrop */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n  overflow-y: auto;\n}\n\n.small-modal {\n  background-color: #FFCC01;\n  border-radius: 2vw;\n  min-width: 20%;\n  min-height: 20%;\n  padding: 2vw;\n  text-align: center;\n  position: relative;\n  pointer-events: auto;\n}\n\n.manhours-input{\n  border: 0.15vw solid #221F20;\n  font-size: 1vw;\n  padding: 0;\n  margin: 0;\n}\n\n.manhours-content {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center; /* Aligns input vertically */\n  padding: .8vw; /* Adjust as needed */\n}\n\n.manhours-confirm-btn{\n  font-size: 1.5vw;\n  border-radius: 0.5vw;\n  padding: 0.4vw;\n}\n\n.close-btn{\n  position: absolute;\n  top: 0.5vw;;\n  right: 0.5vw;\n  border-radius: 3vw;\n  padding: 0.2vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
