// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/img/supbackground.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/img/oi-email-b.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/img/oi-lock-b.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supervisor-background{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
}

.supervisor-text{
  color: #221F20;
}

.supervisor-colors{
  background-color: #FFCC01;
  color: #221F20;
  border-color: #221F20 !important; 
}

.supervisor-btn{
  background-color: #221F20;
  color: #FFCC01;
  border: none;
  font-family: 'Montserrat';
}

.supervisor-btn:hover{
  background-color: black;
}

#emailIconContainer-b {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

#passwordIconContainer-b {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

#emailIconContainer-b, #passwordIconContainer-b {
  width: 2vw;
  height: 2vw;
  background-size: cover;
}

.supervisor-input{
  background-color: #FFCC01;
}

.supervisor-input:-webkit-autofill,
.supervisor-input:-webkit-autofill:hover, 
.supervisor-input:-webkit-autofill:focus, 
.supervisor-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100vh #FFCC01 inset !important;
  -webkit-text-fill-color: #221F20 !important;
  font-size: 1.5vw !important;
}

.supervisor-logo img{
  width: 35%;
}`, "",{"version":3,"sources":["webpack://./src/supervisorlogin.css"],"names":[],"mappings":"AAAA;EACE,yDAAwD;AAC1D;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yDAAqD;AACvD;;AAEA;EACE,yDAAoD;AACtD;;AAEA;EACE,UAAU;EACV,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;;EAIE,wDAAwD;EACxD,2CAA2C;EAC3C,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".supervisor-background{\n  background-image: url('../public/img/supbackground.png'); \n}\n\n.supervisor-text{\n  color: #221F20;\n}\n\n.supervisor-colors{\n  background-color: #FFCC01;\n  color: #221F20;\n  border-color: #221F20 !important; \n}\n\n.supervisor-btn{\n  background-color: #221F20;\n  color: #FFCC01;\n  border: none;\n  font-family: 'Montserrat';\n}\n\n.supervisor-btn:hover{\n  background-color: black;\n}\n\n#emailIconContainer-b {\n  background-image: url('../public/img/oi-email-b.png');\n}\n\n#passwordIconContainer-b {\n  background-image: url('../public/img/oi-lock-b.png');\n}\n\n#emailIconContainer-b, #passwordIconContainer-b {\n  width: 2vw;\n  height: 2vw;\n  background-size: cover;\n}\n\n.supervisor-input{\n  background-color: #FFCC01;\n}\n\n.supervisor-input:-webkit-autofill,\n.supervisor-input:-webkit-autofill:hover, \n.supervisor-input:-webkit-autofill:focus, \n.supervisor-input:-webkit-autofill:active {\n  -webkit-box-shadow: 0 0 0 100vh #FFCC01 inset !important;\n  -webkit-text-fill-color: #221F20 !important;\n  font-size: 1.5vw !important;\n}\n\n.supervisor-logo img{\n  width: 35%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
