import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './manager.css';
import EmployeesModal from './EmployeesModal';
import ImportModal from './ImportModal';
import AssignEmployeeModal from './AssignEmployeeModal';
import CreateTasksModal from './CreateTasksModal';
import ManhoursModal from './ManhoursModal';
import HelpModal from './HelpModal';
import TaskModal from './TaskModal';

function ManagerPage() {
  const location = useLocation();
  const userID = location.state?.userID;
  const manName = location.state?.name;
  const [manHours, setManHours] = useState();
  const [employeesModalOpen, setEmployeesModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false); //help Page
  const [taskModalOpen, setTaskModalOpen] = useState(false); //Task Page
  const [assignEmployeeModalOpen, setAssignEmployeeModalOpen] = useState(false);
  const [createTasksModalOpen, setCreateTasksModalOpen] = useState(false);
  const [manhoursModalOpen, setManhoursModalOpen] = useState(false);
  const [selectedTaskID, setSelectedTaskID] = useState(null);
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [tasksInTable, setTasksInTable] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekDates, setWeekDates] = useState([]);
  const [selectedDayDate, setSelectedDayDate] = useState(null);

  // Define a mapping of day abbreviations
  const dayAbbreviations = {
    'Monday': 'MON',
    'Tuesday': 'TUE',
    'Wednesday': 'WED',
    'Thursday': 'THU',
    'Friday': 'FRI'
  };

  const openEmployeesModal = () => {
    setEmployeesModalOpen(true);
  };

  const closeEmployeesModal = () => {
    setEmployeesModalOpen(false);
    fetchTasks();
  };

  const toggleImportModal = () => {
    setImportModalOpen(!importModalOpen);
    fetchTasks();
  };

  const toggleHelpModal = () => {
    setHelpModalOpen(!helpModalOpen);
  };

  const toggleTaskModal = () => {
    setTaskModalOpen(!taskModalOpen);
    fetchTasks();
  };

  const toggleManHoursModal = () => {
    setManhoursModalOpen(!manhoursModalOpen);
    fetchManHours();
  };

  // Function to fetch man hours
  const fetchManHours = useCallback(async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getManHours/${userID}`);
      if (response.ok) {
        const result = await response.json();
        setManHours(result.manHours);
      } else {
        console.error('Failed to fetch man hours.');
        // Handle the error or show an error message to the user
      }
    } catch (error) {
      console.error('Error while fetching man hours:', error);
      // Handle the error or show an error message to the user
    }
  }, [userID]);

  // Effect to fetch man hours when component mounts or userID changes
  useEffect(() => {
    fetchManHours();
  }, [userID, fetchManHours]);

  const openAssignEmployeeModal = (taskID) => {
    setSelectedTaskID(taskID);
    setAssignEmployeeModalOpen(true);
  };

  const closeAssignEmployeeModal = () => {
    setSelectedTaskID(null);
    setAssignEmployeeModalOpen(false);
    fetchTasks();
  };

  const openCreateTasksModal = () => {
    setCreateTasksModalOpen(true);
  };

  const closeCreateTasksModal = () => {
    setCreateTasksModalOpen(false);
    fetchTasks();
  };

  const handleManhoursUpdate = (newManhours) => {
    setManHours(newManhours);
  };

  const handleLogout = () => {
    navigate('/');
  }

  const fetchTasks = useCallback(async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getTasks/${userID}`, {
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();
        setTasks(result);

        // Fetch and set the list of assigned employees for each task
        const tasksWithAssignedEmployees = await Promise.all(result.map(async (task) => {
          const empResponse = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getEmpList/${task.taskID}`);
          if (empResponse.ok) {
            const empResult = await empResponse.json();
            task["Assigned Employees"] = empResult.empList;
          }
          return task;
        }));

        var selectedDateObj;

        if (selectedDayDate === null) {
          selectedDateObj = new Date();
        }
        else {
          selectedDateObj = new Date(selectedDayDate);
        }

        // Filter tasks based on the selected date
        const filteredTasks = tasksWithAssignedEmployees.filter(task => {
          // Convert UTC date string to Date object in local time zone
          const taskDateUTC = new Date(task['Set Date']);
          const taskDate = new Date(taskDateUTC.getTime() + taskDateUTC.getTimezoneOffset() * 60000);
          return taskDate.getFullYear() === selectedDateObj.getFullYear() &&
            taskDate.getMonth() === selectedDateObj.getMonth() &&
            taskDate.getDate() === selectedDateObj.getDate()
        });

        setTasksInTable(filteredTasks);
      } else {
        console.error('Failed to fetch tasks.');
        // Handle the error or show an error message to the user
      }
    } catch (error) {
      console.error('Error while fetching tasks:', error);
      // Handle the error or show an error message to the user
    }
  }, [userID, selectedDayDate]);

  useEffect(() => {
    if (selectedDayDate === null) {
      fetchTasks();
    }
  }, [userID, currentDate, fetchTasks, selectedDayDate]);

  const handleChange = (field, value, taskID) => {
    // Find the index of the task with the given taskID
    const taskIndex = tasks.findIndex((task) => task.taskID === taskID);

    if (taskIndex !== -1) {
      setTasks((prevTasks) =>
        prevTasks.map((task, index) =>
          index === taskIndex ? { ...task, [field]: value } : task
        )
      );
    }
  };

  const handleEdit = async (taskID) => {
    try {
      // Fetch the task data for the given taskID
      const taskToUpdate = tasks.find((task) => task.taskID === taskID);
      console.log(taskID);
      console.log(taskToUpdate);

      // Make a POST request to the server to update the task
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/editTask/${taskID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mwc: taskToUpdate['Main Work Center'],
          cs: taskToUpdate['Completion Status'],
          earlyDate: taskToUpdate['Early Date'],
          pdmc: taskToUpdate['Plan Date Maintanence Call'],
          lateDate: taskToUpdate['Late Date'],
          os: taskToUpdate['Operational Status'],
          adminNum: taskToUpdate['Admin Number'],
          modelNum: taskToUpdate['Model Number'],
          techDescription: taskToUpdate['Description of Technical Object'],
          serialNum: taskToUpdate['Serial Number'],
          maintanenceDescription: taskToUpdate['Maintanence Item Description'],
          setDate: taskToUpdate['Set Date']
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Task updated successfully:', result.task);
        fetchTasks();
      } else {
        console.error('Failed to update task.');
        // Handle the error or show an error message to the user
      }
    } catch (error) {
      console.error('Error while updating task:', error);
      // Handle the error or show an error message to the user
    }
  };

  const handleDelete = async (taskID, userID) => {
    try {
      // Find the task to delete by its ID
      const taskToDelete = tasks.find(task => task.taskID === taskID);


      // If task is found and has a serial number, ask for confirmation
      if (taskToDelete && window.confirm(`Are you sure you want to delete ${taskToDelete['Description of Technical Object']}, "${taskToDelete['Serial Number']}"?`)) {
        const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/delTask/${taskID}/${userID}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          console.log('Task deleted successfully.');
          fetchTasks(); // Assuming you have a method to refresh the tasks list
        } else {
          console.error('Failed to delete task.');
        }
      }
    } catch (error) {
      console.error('Error while deleting task:', error);
    }
  };

  // Function to get rid of the commas taken from the getCurrentDate
  function removeLastLetter(inputString) {
    // Check if the string is not empty
    if (inputString.length > 0) {
      // Use slice to create a new string without the last character
      return inputString.slice(0, -1);
    } else {
      // If the string is empty, return it as is
      return inputString;
    }
  }

  // Function to get the current date in the format: Monday March 4th, 2024
  const getCurrentDate = () => {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    const [weekday, month, day, year] = currentDate.split(' ');
    const newWeekday = removeLastLetter(weekday);
    return `${newWeekday}: ${month} ${parseInt(day, 10)}, ${year}`;
  };

  const handleLeftArrow = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 7); // Move back by 7 days (a week)
      return newDate;
    });
  };

  const handleRightArrow = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 7); // Move forward by 7 days (a week)
      return newDate;
    });
  };

  // Whenever the currentDate is updated, the days of the week update
  useEffect(() => {

    // Define getWeekDates inside useEffect
    const getWeekDates = () => {
      const daysInWeek = [];
      const startDate = new Date(currentDate);
      const currentDay = startDate.getDay();
      const daysUntilMonday = (currentDay === 0 ? 6 : currentDay - 1);
      startDate.setDate(startDate.getDate() - daysUntilMonday);

      for (let i = 0; i < 5; i++) {
        const day = new Date(startDate);
        day.setDate(startDate.getDate() + i);
        const formattedDate = `${day.toLocaleDateString(undefined, { weekday: 'long' })}: ${day.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })}`;
        daysInWeek.push(formattedDate);
      }

      return daysInWeek;
    };

    // Call getWeekDates to get the array of week dates and update the state
    const weekDates = getWeekDates();
    setWeekDates(weekDates);
  }, [currentDate]); // This effect runs whenever currentDate changes

  // Update the Date whenever a Day button is clicked
  const handleDayButtonClick = (selectedDate) => {

    setSelectedDayDate(selectedDate);

    const selectedDateObj = new Date(selectedDate);

    // Filter tasks based on the selected date
    const filteredTasks = tasks.filter(task => {
      // Convert UTC date string to Date object in local time zone
      const taskDateUTC = new Date(task['Set Date']);
      const taskDate = new Date(taskDateUTC.getTime() + taskDateUTC.getTimezoneOffset() * 60000);

      return taskDate.getDate() === selectedDateObj.getDate();
    });

    setTasksInTable(filteredTasks);

  };

  return (
    <div className="manager-container">
      <div className="login-header manager-colors">
        <div className="leftright-section">
          <div>
            <p className="manager-info font-size2">Name: {manName}</p>
            <p className="manager-info font-size1-5">Manhours: {manHours}</p>
          </div>
        </div>
        <div>
          <button className="highlight-btn font-size1 manager-highlight" onClick={toggleManHoursModal} title="Edit manhours">Manhours</button>
          <button className="highlight-btn font-size1 manager-highlight" onClick={toggleHelpModal} title="Help menu">Help</button>
          <button className="highlight-btn font-size1 manager-highlight" onClick={toggleTaskModal} title="Tasks">Tasks</button>
          <button className="highlight-btn font-size1 manager-highlight" onClick={openEmployeesModal} title="Edit employees">Employees</button>
          <button className="highlight-btn font-size1 manager-highlight" onClick={toggleImportModal} title="Import data">Import</button>
        </div>
        <div className="leftright-section right-section">
          <button className="header-btn manager-btn" onClick={handleLogout}>Logout</button>
        </div>
      </div>
      <div className="flex-center margin-top1">
        <div className="flex-center manager-colors border-radius2">
          <button className="arrow-btn manager-btn large-image" onClick={handleLeftArrow} title="Previous Week">
            <img src='./img/leftarrow.svg' alt="Left Arrow" />
          </button>
          {weekDates.map((date, index) => {
            const [dayName, rest] = date.split(': ');
            const abbreviation = dayAbbreviations[dayName];
            return (
              <button key={index} className="highlight-btn day-btn manager-highlight" onClick={() => handleDayButtonClick(date)}>
                <span className="font-size2">{abbreviation}</span> 
                <span className="font-size1">{rest}</span>
              </button>
            );
          })}
          <button className="arrow-btn manager-btn large-image" onClick={handleRightArrow} title="Next Week">
            <img src='./img/rightarrow.svg' alt="Right " />
          </button>
        </div>
      </div>
      <p className="login-text">{selectedDayDate || getCurrentDate()}</p> {/* Display the selected date above the day box */}
      <div>
        <div className="day-box">
          <table className="task-table">
            <thead>
              <tr>
                <th className="taskTh manager-colors altBorder-manager">Admin Number</th>
                <th className="taskTh manager-colors altBorder-manager">Completion Status</th>
                <th className="taskTh manager-colors altBorder-manager">Early Date</th>
                <th className="taskTh manager-colors altBorder-manager">Late Date</th>
                <th className="taskTh manager-colors altBorder-manager">Maintenance Item Description</th>
                <th className="taskTh manager-colors altBorder-manager">Plan Date Maintenance Call</th>
                <th className="taskTh manager-colors altBorder-manager">Description of Technical Object</th>
                <th className="taskTh manager-colors altBorder-manager">Operational Status</th>
                <th className="taskTh manager-colors altBorder-manager">Model Number</th>
                <th className="taskTh manager-colors altBorder-manager">Serial Number</th>
                <th className="taskTh manager-colors altBorder-manager">Main Work Center</th>
                <th className="taskTh manager-colors altBorder-manager">Assigned Employees</th>
                <th className="taskTh manager-colors altBorder-manager">Actions</th>
              </tr>
            </thead>
            <tbody>
              {tasksInTable.map((task, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Admin Number', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Admin Number']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Completion Status', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Completion Status']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Early Date', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Early Date']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Late Date', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Late Date']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Maintanence Item Description', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Maintanence Item Description']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Plan Date Maintanence Call', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Plan Date Maintanence Call']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Description of Technical Object', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Description of Technical Object']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Operational Status', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Operational Status']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Model Number', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Model Number']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Serial Number', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Serial Number']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable onBlur={(e) => handleChange('Main Work Center', e.target.innerText, task.taskID)} suppressContentEditableWarning={true}>
                      {task['Main Work Center']}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <div contentEditable={false}>
                      {task['Assigned Employees'].join(', ')}
                    </div>
                  </td>
                  <td className="data-td font-size1">
                    <button className="manager-btn small-btn" onClick={() => handleEdit(task['taskID'])} title="Save Changes">
                      <img src='./img/save.svg' alt="Save Changes" />
                    </button>
                    <button className="manager-btn small-btn" onClick={() => handleDelete(task['taskID'])} title="Delete Task">
                      <img src='./img/delete.svg' alt="Delete" />
                    </button>
                    <button className="manager-btn small-btn" onClick={() => openAssignEmployeeModal(task['taskID'])} title="Assign Employees">
                      <img src='./img/assign_employee.svg' alt="Assign Employees" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button className="create-task-btn manager-btn large-image border-radius2" 
            onClick={openCreateTasksModal} title="Create Task">
              <img src='./img/add.svg' alt="Create Task" />
            </button>
          </div>
        </div>
      </div>

      {employeesModalOpen && <EmployeesModal onClose={closeEmployeesModal} userID={userID} />}
      {importModalOpen && <ImportModal onClose={toggleImportModal} userID={userID} />}
      {assignEmployeeModalOpen && <AssignEmployeeModal onClose={closeAssignEmployeeModal} taskID={selectedTaskID} userID={userID} />}
      {createTasksModalOpen && <CreateTasksModal onClose={closeCreateTasksModal} userID={userID} selectedDayDate={selectedDayDate} />}
      {manhoursModalOpen && <ManhoursModal onClose={toggleManHoursModal} userID={userID} onUpdateManhours={handleManhoursUpdate} />}
      {helpModalOpen && <HelpModal onClose={toggleHelpModal} />}
      {taskModalOpen && <TaskModal onClose={toggleTaskModal} tasks={tasks} setTasks={setTasks} userID={userID}/>}
    </div>
  );
}

export default ManagerPage;