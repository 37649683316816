import React from 'react';
import './SupervisorHelpModal.css'; // Your CSS file for styling

const HelpModal = ({ onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="supervisor-help-modal-content" onClick={e => e.stopPropagation()}>
        <div className="font-size1"><p className="help-text">
            This page displays the list of all tasks scheduled by the chosen manager for 
            the currently selected day. To select a different day, use the left and 
            right arrow buttons to change the week and click on the day you wish to view.
          </p></div>
        <div className="font-size1"><p className="help-text">
            The manager whose schedule you are viewing and their corresponding number of 
            manhours are visible in the top-left of the page. You can use the "Back" 
            button in the top-right of the page to return to your dashboard to select a 
            different manager.
          </p></div>
      </div>
    </div>
  );
};

export default HelpModal;
