import React, { useState, useEffect, useCallback } from 'react';
import './AssignEmployeeModal.css';

const initialData = [];

const AssignEmployeeModal = ({ onClose, taskID, userID }) => {
  const [data, setData] = useState(initialData);

  const fetchData = useCallback(async () => {
    console.log(userID);

    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getEmp/${userID}`, {
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.json();
        setData(result.emps.Items);
      } else {
        console.error('Error fetching employees:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    }
  }, [userID]);

  useEffect(() => {
    fetchData();
  }, [userID, fetchData]);

  const addEmployeeToTask = async (empID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/addEmployeeToTask/${taskID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ empID }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Employee added to task:', result);
        addTaskToEmp(empID, taskID); // Call function to add task to employee
      } else {
        console.error('Error adding employee to task:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding employee to task:', error.message);
    }
  };

  const removeEmployeeFromTask = async (empID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/removeEmployeeFromTask/${taskID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ empID }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Employee removed from task:', result);
        remTaskFromEmp(empID, taskID); // Call function to remove task from employee
      } else {
        console.error('Error removing employee from task:', response.statusText);
      }
    } catch (error) {
      console.error('Error removing employee from task:', error.message);
    }
  };

  const addTaskToEmp = async (empID, taskID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/addTaskToEmp/${empID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskID }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Task added to employee:', result);
        fetchData(); // Refresh the data after the change
      } else {
        console.error('Error adding task to employee:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding task to employee:', error.message);
    }
  };

  const remTaskFromEmp = async (empID, taskID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/remTaskFromEmp/${empID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskID }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Task removed from employee:', result);
        fetchData(); // Refresh the data after the change
      } else {
        console.error('Error removing task from employee:', response.statusText);
      }
    } catch (error) {
      console.error('Error removing task from employee:', error.message);
    }
  };

  // Check whether the Employee is Assigned To Task returing the corresponging Boolean
  const isEmployeeAssignedToTask = (employee, taskID) => {
    // Convert taskID to string to ensure consistent comparison
    const normalizedTaskID = String(taskID);

    const isIDInList = employee['Assigned Tasks'].includes(normalizedTaskID);

    console.log(isIDInList);

    return (
      isIDInList
    );
  };

  // Update in Real Time the State of the Task/Employee
  const toggleEmployeeAssignment = async (empID) => {
    const updatedData = data.map((item) => {
      if (item.ID === empID) {
        // Toggle the assignment status for the specific employee
        const isAssigned = isEmployeeAssignedToTask(item, taskID);
        if (isAssigned) {
          removeEmployeeFromTask(empID);
        } else {
          addEmployeeToTask(empID);
        }

        // Update the local state to reflect the change
        return {
          ...item,
          'Assigned Tasks': isAssigned
            ? item['Assigned Tasks'].filter((task) => task !== taskID)
            : [...item['Assigned Tasks'], taskID],
        };
      }
      return item;
    });

    setData(updatedData);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="assignEmployee-box" onClick={e => e.stopPropagation()}>
        <p className="assignEmployee-title">Assign Employee</p>
        <button className="close-btn supervisor-btn medium-image" onClick={onClose}>
          <img src='./img/close-b.svg' alt="Close B" />
        </button>

        <table className="employee-modal-table">
          <thead>
            <tr>
              <th className="taskTh manager-colors altBorder-manager">Assign Task</th>
              <th className="taskTh manager-colors altBorder-manager">Name</th>
              <th className="taskTh manager-colors altBorder-manager">Job Role</th>
              <th className="taskTh manager-colors altBorder-manager">Skills</th>
              <th className="taskTh manager-colors altBorder-manager">Unavailability</th>
              <th className="taskTh manager-colors altBorder-manager">MOS</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.ID}
                style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                <td className="data-td font-size1">
                  {/* Display a checkbox or indicator based on the employee's assignment */}
                  <input
                    className="taskCheck"
                    type="checkbox"
                    checked={isEmployeeAssignedToTask(item, taskID)}
                    onChange={() => toggleEmployeeAssignment(item.ID)}
                    aria-label={`Assign task to ${item.name}`}
                    title="Assign employee to task"
                  />
                </td>
                <td className="data-td font-size1" >{item.name}</td>
                <td className="data-td font-size1" >{item.jobRole}</td>
                <td className="data-td font-size1" >{item.skills}</td>
                <td className="data-td font-size1" >{item.name}</td>
                <td className="data-td font-size1" >{item.mos}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AssignEmployeeModal;