import React, { useState, useEffect, useCallback } from 'react';
import './EmployeesModal.css';

const initialData = [];

const EmployeesModal = ({ onClose, userID }) => {
  const [data, setData] = useState(initialData);
  const [newItem, setNewItem] = useState({
    name: '',
    age: '',
    rank: '',
    battalion: '',
    company: '',
    jobRole: '',
    troop: '',
    skills: '',
    unavailability: '',
    mos: '',
  });

  const fetchData = useCallback(async () => {
    try {
      const empResponse = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getEmp/${userID}`, {
        method: 'POST',
      });

      if (empResponse.ok) {
        const empResult = await empResponse.json();
        const empData = empResult.emps.Items;

        // Fetch tasks for each employee
        for (let i = 0; i < empData.length; i++) {
          const taskResponse = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getTaskList/${empData[i].ID}`, {
            method: 'GET',
          });

          if (taskResponse.ok) {
            const taskResult = await taskResponse.json();
            const tasks = taskResult.taskList;

            // Update Assigned Tasks for the employee
            empData[i]['Assigned Tasks'] = tasks;
          } else {
            console.error('Error fetching tasks for employee:', taskResponse.statusText);
          }
        }

        setData(empData);
      } else {
        console.error('Error fetching employees:', empResponse.statusText);
      }
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    }
  }, [userID]);

  useEffect(() => {
    fetchData();
  }, [userID, fetchData]);

  const handleChange = (field, value, id) => {
    if (id !== undefined) {
      setData((prevData) =>
        prevData.map((item) =>
          item.ID === id ? { ...item, [field]: value } : item
        )
      );
    } else {
      setNewItem((prevItem) => ({ ...prevItem, [field]: value }));
    }
  };

  const handleAdd = async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/addEmp/${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newItem),
      });

      if (response.ok) {
        const result = await response.json();
        setData((prevData) => [...prevData, result.emp]);
        setNewItem({
          name: '',
          age: '',
          rank: '',
          battalion: '',
          company: '',
          jobRole: '',
          troop: '',
          skills: '',
          unavailability: '',
          mos: '',
        });
        fetchData();
      } else {
        console.error('Error adding employee:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding employee:', error.message);
    }
  };

  const handleDelete = async (id, userID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/delEmp/${id}/${userID}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedData = data.filter((item) => item.ID !== id);
        setData(updatedData);
        fetchData();
      } else {
        console.error('Error deleting employee:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting employee:', error.message);
    }
  };

  const handleEdit = async (id, oldItem) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/editEmp/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(oldItem),
      });

      if (response.ok) {
        console.log(oldItem);
        const result = await response.json();
        const updatedData = data.map((item) => (item.ID === id ? result.emp : item));
        setData(updatedData);
        handleEditUnavailability(id, oldItem['unavailability']);
      } else {
        console.error('Error editing employee:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing employee:', error.message);
    }
  };

  const handleEditUnavailability = async (id, unavailability) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/editUnavailability/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ unavailability }),
      });

      if (response.ok) {
        const result = await response.json();
        const updatedData = data.map((item) => (item.ID === id ? result.emp : item));
        setData(updatedData);
        fetchData();
      } else {
        console.error('Error editing unavailability:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing unavailability:', error.message);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="large-modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <p className="modal-text-header">All Employees</p>
          <button className="employee-close-btn supervisor-btn large-image" onClick={onClose}>
            <img src='./img/close-b.svg' alt="Close B" />
          </button>
        </div>


        <table className="employee-modal-table">
          <thead>
            <tr>
              <th className="taskTh manager-colors altBorder-manager">Name</th>
              <th className="taskTh manager-colors altBorder-manager">Age</th>
              <th className="taskTh manager-colors altBorder-manager">Rank</th>
              <th className="taskTh manager-colors altBorder-manager">Battalion</th>
              <th className="taskTh manager-colors altBorder-manager">Company</th>
              <th className="taskTh manager-colors altBorder-manager">Job Role</th>
              <th className="taskTh manager-colors altBorder-manager">Troop</th>
              <th className="taskTh manager-colors altBorder-manager">Skills</th>
              <th className="taskTh manager-colors altBorder-manager">Unavailability</th>
              <th className="taskTh manager-colors altBorder-manager">MOS</th>
              <th className="taskTh manager-colors altBorder-manager">Assigned Tasks</th>
              <th className="taskTh manager-colors altBorder-manager">Buttons</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.ID} style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                <td className="data-td font-size1" contentEditable={false}>
                  {item.name}</td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('age', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.age}
                </td>
                <td className="data-td font-size1" contentEditable={false}>
                  {item.rank}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('battalion', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.battalion}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('company', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.company}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('jobRole', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.jobRole}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('troop', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.troop}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('skills', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.skills}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('unavailability', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item['unavailability']}
                </td>
                <td className="data-td font-size1" contentEditable onBlur={(e) => handleChange('mos', e.target.innerText, item.ID)} suppressContentEditableWarning={true}>
                  {item.mos}
                </td>
                <td className="data-td font-size1" contentEditable={false}>
                  {item['Assigned Tasks'] ? item['Assigned Tasks'].join(', ') : ''}
                </td>
                <td className="data-td font-size1">
                  <button className="manager-btn small-btn" onClick={() => handleEdit(item.ID, item)} title="Save Changes">
                    <img src='./img/save.svg' alt="Save Changes" />
                  </button>
                  <button className="manager-btn small-btn" onClick={() => handleDelete(item.ID)} title="Remove Employee">
                    <img src='./img/delete.svg' alt="Delete" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tbody>
                <tr>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.name} onChange={(e) => handleChange('name', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.age} onChange={(e) => handleChange('age', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.rank} onChange={(e) => handleChange('rank', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.battalion} onChange={(e) => handleChange('battalion', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.company} onChange={(e) => handleChange('company', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.jobRole} onChange={(e) => handleChange('jobRole', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.troop} onChange={(e) => handleChange('troop', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.skills} onChange={(e) => handleChange('skills', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.unavailability} onChange={(e) => handleChange('unavailability', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background">
                    <input className="employeeInput" type="text" value={newItem.mos} onChange={(e) => handleChange('mos', e.target.value)} />
                  </td>
                  <td className="data-td font-size1 input-background"></td>
                  <td className="data-td font-size1 input-background">
                    <button className="manager-btn small-btn" onClick={handleAdd} title="Create New Employee">
                      <img src='./img/add.svg' alt="Confirm new user" />
                    </button>
                  </td>
                </tr>
              </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmployeesModal;
