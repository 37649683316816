import React from "react";
import {Routes, Route} from 'react-router-dom';
import LogInPage from './login.js';
import SupervisorLogInPage from './supervisorlogin.js';
import ManagerPage from './manager.js';
import SupervisorHomePage from './supervisorhome.js';
import './App.css';
import ScheduleViewPage from "./schedule.js";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return(
    <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path="SupervisorLogIn" element={<SupervisorLogInPage />} />
        <Route path="ManagerView" element={<ManagerPage />} />
        <Route path="SupervisorHome" element={<SupervisorHomePage />} />
        <Route path="Schedule/:superID/:managerID" element={<ScheduleViewPage />} />
    </Routes>
  );
}

export default App;
