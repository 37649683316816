import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './supervisorlogin.css';

const SupervisorLogInPage = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {

    const login = email;
    var loginDetails = {login: login, password: password };
    var loginJSON = JSON.stringify(loginDetails);

    try {
      const response = await fetch('https://www.maintenancesmartcalendar.com/api/service/login/supervisor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: loginJSON,
      });

      if (response.ok) {
        //If successful move to Supervisor Home
        const result = await response.json();
        console.log('Login successful:', result.user);
        navigate("/SupervisorHome", { state: { superID: result.user.Item.ID } });
      } else {
        //Login Failed
        const errorData = await response.json();
        console.error('Login failed:', errorData);
        
      }
    } catch (error) {
      //Random Errors
      console.log('Error during login:', error);
    }
  };

  return (
    <div className="login-page-container supervisor-background">
      <div className="login-header supervisor-colors">
        <div className="left-selection">
          <img src="./img/ArmyLogoButton.png" alt="Army Logo"/>
        </div>
        <div>
          <p className="header-text supervisor-text">Maintenance Smart Calendar</p>
        </div>
        <div>
          <Link to="/">
            <button className="header-btn supervisor-btn">Manager Login</button>
          </Link>
        </div>
      </div>
      <div className="login-container supervisor-colors">
        <p className="login-text supervisor-text">
          Supervisor Login</p>
        <div className="main-image supervisor-logo">
          <img src="./img/oi-shield-check-fill.svg" alt="Shield Check"></img>
        </div>
        <form>
          <div className="form-group-1">
          <span className="icon-container" id="emailIconContainer-b"></span>
            <input
              className="login-input supervisor-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="EMAIL"
              />
          </div>
          <div className="form-group-2">
            <span className="icon-container" id="passwordIconContainer-b"></span>
            <input
              className="login-input supervisor-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="PASSWORD"
            />
          </div>
          <div>
            <button type="button" className="login-btn supervisor-btn" onClick={handleLogin}>LOGIN</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SupervisorLogInPage;
