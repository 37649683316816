// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supervisor-display {
  margin-top: 1%;
  flex: 1 1;
}

.supervisor-row {
  position: relative;
  border: none;
  border-radius: .2vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0.1vw;
}

.supervisor-info-row{
  display:flex;
  text-align: center;
}

.supervised-manager {
  border: 0.3vw solid #221F20;
  background-color: #FFCC01;
  width: 17.5vw;
  display: inline-block;
  margin: 1vw;
  padding: 0.4vw;
  border-radius: 2vw;
}

.supervisor-title{
  margin: 0.1vw;
  text-align: left;
  font-size: 1.2vw;
  font-weight: bold;
}

.supervisor-details{
  margin: 0.1vw;
  text-align: left;
  font-size: 1.2vw;
}

.view-btn{
  margin: 0.1vw;
  padding: 0.2vw;

  font-size: 1.8vw;

  border-radius: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/supervisorhome.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,SAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,aAAa;EACb,qBAAqB;EACrB,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,cAAc;;EAEd,gBAAgB;;EAEhB,kBAAkB;AACpB","sourcesContent":[".supervisor-display {\n  margin-top: 1%;\n  flex: 1;\n}\n\n.supervisor-row {\n  position: relative;\n  border: none;\n  border-radius: .2vh;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  margin: 0.1vw;\n}\n\n.supervisor-info-row{\n  display:flex;\n  text-align: center;\n}\n\n.supervised-manager {\n  border: 0.3vw solid #221F20;\n  background-color: #FFCC01;\n  width: 17.5vw;\n  display: inline-block;\n  margin: 1vw;\n  padding: 0.4vw;\n  border-radius: 2vw;\n}\n\n.supervisor-title{\n  margin: 0.1vw;\n  text-align: left;\n  font-size: 1.2vw;\n  font-weight: bold;\n}\n\n.supervisor-details{\n  margin: 0.1vw;\n  text-align: left;\n  font-size: 1.2vw;\n}\n\n.view-btn{\n  margin: 0.1vw;\n  padding: 0.2vw;\n\n  font-size: 1.8vw;\n\n  border-radius: 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
