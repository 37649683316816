import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './supervisorhome.css';

const managerData = [];

const SupervisorHomePage = () => {

  // For functions and displays
  const location = useLocation();
  const locationState = location.state;
  const superID = locationState ? locationState.superID : '';
  const [data, setData] = useState(managerData);
  const navigate = useNavigate();

  // Get the managers' info to create the displays
  const getManagers = async () => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/getMan/${superID}`, {
        method: 'GET',
      });

      // If successful
      if (response.ok) {
        const result = await response.json();

        // Set the managers
        setData(result.managers.Items);
      } else {

        // Otherwise display an error message
        console.error('Error fetching managers:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching managers:', error.message);
    }
  }

  // If coming from the login page or the back button on the schedule page, get the managers' info
  useEffect(() => {
    if (superID) {
      getManagers();
    }
  }, [superID]);

  // To logout
  const handleLogout = () => {
    navigate('/SupervisorLogin');
  }

  // Create the boxes to display the info for each manager and buttons to view their schedule
  function CreateManagersDisplay() {
    const managerViewInfo = data.map((manager) =>
      <div className="supervised-manager">
        <div className="supervisor-info-row">
         <p className="supervisor-title">Name: </p>
         <p className="supervisor-details">{manager.name}</p>
        </div>
        <div className="supervisor-info-row">
          <p className="supervisor-title">Rank: </p>
          <p className="supervisor-details">{manager.rank}</p>

        </div>
        <div className="supervisor-info-row">
          <p className="supervisor-title">Unit: </p>
          <p className="supervisor-details">{manager.unit}</p>
        </div>
        <div className="supervisor-info-row">
          <p className="supervisor-title">Man-Hours: </p>
          <p className="supervisor-details">{manager.manhours}</p>
        </div>
       
        <Link to={{
          pathname: `/schedule/${superID}/${manager.ID}`,
          state: { managerName: manager.name, managerManhours: manager.manhours }
        }}>
          <button className="view-btn supervisor-btn">View</button>
        </Link>
      </div>
    );
    return (
      <div className="supervisor-row">
        {managerViewInfo}
      </div>
    );
  }

  return (
    <div className="manager-container">
      <div className="login-header supervisor-colors">
        <div className="leftright-section">
          <p className="manager-info font-size2">Unit Name: {superID}</p>
        </div>

        <div>
          <p className="header-text supervisor-text">Home</p>
        </div>

        <div className="leftright-section right-section">
            <button className="header-btn supervisor-btn" onClick={handleLogout}>Logout</button>
        </div>
      </div>
      <div className="supervisor-display">
        <CreateManagersDisplay />
      </div>
    </div>
  );
}

export default SupervisorHomePage;