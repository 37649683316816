// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-spinner{
  width: 20vw;
}

.import-back{
  margin-top: 1vw;
}

.spinner-load{
  font-size: 1.5vw;
}

.fileInput{
  font-size: 1vw !important;
}`, "",{"version":3,"sources":["webpack://./src/ImportModal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".import-spinner{\n  width: 20vw;\n}\n\n.import-back{\n  margin-top: 1vw;\n}\n\n.spinner-load{\n  font-size: 1.5vw;\n}\n\n.fileInput{\n  font-size: 1vw !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
