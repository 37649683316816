import React, { useState } from 'react';
import './CreateTasksModal.css';

const CreateTasksModal = ({ onClose, userID, selectedDayDate }) => {
  const formattedDate = new Date(selectedDayDate);
  const year = formattedDate.getFullYear();
  let month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because months are zero-based
  let day = formattedDate.getDate().toString().padStart(2, '0');

  const formattedDateString = `${year}-${month}-${day}`;

  const [formData, setFormData] = useState({
    mwc: '',
    cs: '',
    earlyDate: '',
    pdmc: '',
    lateDate: '',
    os: '',
    adminNum: '',
    modelNum: '',
    techDescription: '',
    serialNum: '',
    maintanenceDescription: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    // Confirmation dialog
    if (window.confirm("Are you sure you have entered everything correctly? Once you create a task, you cannot change it.")) {
      try {
        const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/addTask/${userID}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            setDate: formattedDateString, // Set setDate to selectedDayDate
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data); // You can handle the response as needed
          onClose(); // Close modal on success
        } else {
          console.error('Failed to create tasks');
        }
      } catch (error) {
        console.error('Error creating tasks:', error);
      }
    }
  };


  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="createTasks-modal-content" onClick={e => e.stopPropagation()}>
        <p className="modal-text-header">Create Tasks Modal</p>
        <button className="createTask-close-btn supervisor-btn large-image" onClick={onClose}>
          <img src='./img/close-b.svg' alt="Close B" />
        </button>
        
        {/* Row 1 */}
        <div className="form-row">
          {/* Admin Number */}
          <div className="form-group">
            <label className="font-size1 flex-center">Admin Number</label>
            <input type="text" name="adminNum" value={formData.adminNum} 
            onChange={handleChange} className="createTasksIn medium-input" />
          </div>

          {/* Completion Status */}
          <div className="form-group">
            <label className="font-size1 flex-center">Completion Status</label>
            <select name="cs" value={formData.cs} onChange={handleChange} className="createTasksIn short-input">
              <option value="OPEN">OPEN</option>
              <option value="OVERDUE">OVERDUE</option>
            </select>
          </div>

          {/* Early Date */}
          <div className="form-group">
            <label className="font-size1 flex-center">Early Date</label>
            <input type="date" name="earlyDate" value={formData.earlyDate} 
            onChange={handleChange} className="createTasksIn short-input" />
          </div>

          {/* Late Date */}
          <div className="form-group">
            <label className="font-size1 flex-center">Late Date</label>
            <input type="date" name="lateDate" value={formData.lateDate} 
            onChange={handleChange} className="createTasksIn short-input" />
          </div>

          {/* Plan Date Maintenance Call */}
          <div className="form-group">
            <label className="font-size1 flex-center">Plan Date Maintenance Call</label>
            <input type="date" name="pdmc" value={formData.pdmc} 
            onChange={handleChange} className="createTasksIn short-input" />
          </div>
        </div>

        {/* Row 2 */}
        <div className="form-row">
          {/* Maintenance Item Description */}
          <div className="form-group">
            <label className="font-size1 flex-center">Maintenance Item Description</label>
            <input type="text" name="maintanenceDescription" value={formData.maintanenceDescription} 
            onChange={handleChange} className="createTasksIn large-input" />
          </div>

          {/* Description of Technical Object */}
          <div className="form-group">
            <label className="font-size1 flex-center">Description of Technical Object</label>
            <input type="text" name="techDescription" value={formData.techDescription} 
            onChange={handleChange} className="createTasksIn large-input" />
          </div>

          {/* Operational Status */}
          <div className="form-group">  
            <label className="font-size1 flex-center">Operational Status</label>
            <select name="os" value={formData.os} onChange={handleChange} className="createTasksIn short-input">
              <option value="FMC">FMC</option>
              <option value="NMCS">NMCS</option>
              <option value="NMCM">NMCM</option>
            </select>
          </div>
        </div>
    
        {/* Row 3 */}
        <div className="form-row">
          {/* Model Number */}
          <div className="form-group"></div>
          <div className="form-group">
            <label className="font-size1 flex-center">Model Number</label>
            <input type="text" name="modelNum" value={formData.modelNum} onChange={handleChange} className="createTasksIn medium-input" />
          </div> 

          {/* Serial Number */}
          <div className="form-group">
            <label className="font-size1 flex-center">Serial Number</label>
            <input type="text" name="serialNum" value={formData.serialNum} onChange={handleChange} className="createTasksIn medium-input" />
          </div> 

          {/* Main Work Cente */}
          <div className="form-group">
            <label className="font-size1 flex-center">Main Work Center</label>
            <input type="text" name="mwc" value={formData.mwc} onChange={handleChange} className="createTasksIn medium-input" />
          </div>     
          <div className="form-group"></div> 
        </div>

        <div>
          <button className="manhours-confirm-btn supervisor-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateTasksModal;
