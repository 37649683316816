import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './login.css';

const LogInPage = () => {
  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {

    const login = email;
    var loginDetails = {login: login, password: password };
    var loginJSON = JSON.stringify(loginDetails);

    try {
      const response = await fetch('https://www.maintenancesmartcalendar.com/api/service/login/manager', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: loginJSON,
      });

      if (response.ok) {
        //If successful move to manager view
        const result = await response.json();
        console.log('Login successful:', result.user);
        navigate("/ManagerView", { state: { userID: result.user.Item.ID, name: result.user.Item.name, manhours: result.user.Item.manhours } });
      } else {
        //Login Failed
        const errorData = await response.json();
        console.error('Login failed:', errorData);
        
      }
    } catch (error) {
      //Random Errors
      console.log('Error during login:', error);
    }
  };
/*
*
*
*


*
*
*
*/

  return (
    <div className="login-page-container login-background">
      <div className="login-header manager-colors">
        <div className="left-selection">
          <img src="./img/ArmyLogoButton.png" alt="Army Logo"/>
        </div>
        <div className="btn-container">
          <p className="header-text manager-text">Maintenance Smart Calendar</p>
        </div>
        <div>
          <Link to="/SupervisorLogin">
            <button className="header-btn manager-btn">Supervisor Login</button>
          </Link>
        </div>
      </div>
      <div className="login-container manager-colors">
        <p className="login-text manager-text">
          Manager Login</p>
        <div className="main-image">
          <img src="./img/menu-board.svg" alt="Menu Board"></img>
        </div>
        <form>
          <div className="form-group-1">
            <span className="icon-container" id="emailIconContainer"></span>
            
            <input
              className="login-input manager-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="EMAIL"
            />
          </div>
          <div className="form-group-2">
            <span className="icon-container" id="passwordIconContainer"></span>
            <input
              className="login-input manager-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="PASSWORD"
            />
          </div>
          <div className="btn-container">
            <button type="button" className="login-btn manager-btn" onClick={handleLogin}>LOGIN</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LogInPage;
