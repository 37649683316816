import React, { useState, useEffect } from 'react';
import './ManhoursModal.css';

function ManhoursModal({ onClose, userID, onUpdateManhours }) {
    const [manhours, setManhours] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setError(''); // Reset error state on mount or when userID changes
    }, [userID]);

    const handleConfirm = async () => {
        if (!manhours || isNaN(manhours)) {
            setError('Please enter a valid number for manhours.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/editManhours/${userID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    manhours: Number(manhours),
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Manhours updated:', data);
                onUpdateManhours(data.emp.Item.manhours);
                onClose(); // Close the modal after updating manhours
            } else {
                setError('Failed to update manhours. Please try again later.');
            }
        } catch (error) {
            console.error('Error updating manhours:', error);
            setError('Failed to update manhours. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="small-modal" onClick={e => e.stopPropagation()}>
                <div className="flex-center">
                    <p className="font-size2">Update Manhours</p>
                    <button className="close-btn small-image supervisor-btn" onClick={onClose}>
                        <img src='./img/close-b.svg' alt="Close B" />
                    </button>
                </div>
                <div className="manhours-content">
                    <input
                        className="manhours-input"
                        type="text"
                        value={manhours}
                        onChange={(e) => setManhours(e.target.value)}
                        placeholder="Enter Manhours"
                        disabled={loading}
                    />
                    {error && <p className="font-size0-75 btn-container">{error}</p>}
                </div>
                
                <div className="flex-center">
                    <button className="manhours-confirm-btn supervisor-btn"
                    onClick={handleConfirm} disabled={loading}>
                        {loading ? 'Loading...' : 'Confirm'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ManhoursModal;
