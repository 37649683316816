import React, { useState, useEffect, useMemo } from 'react';
import './TaskModal.css';
import './manager.css'

const TaskModal = ({ onClose, tasks, setTasks, userID }) => {
  const [selectedTasks, setSelectedTasks] = useState([])
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedDate, setSelectedDate] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 100; //Set tasks per page here
  const [selectAll, setSelectAll] = useState(false); //selects all

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTasks(sortedAndPagedTasks.map(task => task.taskID));
    } else {
      setSelectedTasks([]);
    }
    setSelectAll(e.target.checked);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDelete = async (taskID, userID) => {
    try {
      const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/delTask/${taskID}/${userID}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Task deleted successfully.');
      } else {
        console.error('Failed to delete task.');
      }
    } catch (error) {
      console.error('Error while deleting task:', error);
    }
  };

  /*Deletes all the lines at once */
  const handleDeleteSelectedTasks = async () => {
  const numTasks = selectedTasks.length;
  if (numTasks && window.confirm(`Are you sure you want to delete ${numTasks} task(s)?`)) {
    try {
      // Loop through each selected task ID
      for (let taskID of selectedTasks) {
        // Make API call to delete the task
        await handleDelete(taskID, userID);
        
        // Update state to remove the deleted task
        setTasks(currentTasks => currentTasks.filter(task => task.taskID !== taskID));
      }

      // Clear selected tasks after deletion
      setSelectedTasks([]);
    } catch (error) {
      console.error('Error deleting tasks:', error);
    }
  }
};

  const sortedAndPagedTasks = useMemo(() => {
    let sortableTasks = [...tasks];
    if (sortConfig !== null) {
      sortableTasks.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    const firstPageIndex = (currentPage - 1) * tasksPerPage;
    const lastPageIndex = firstPageIndex + tasksPerPage;
    return sortableTasks.slice(firstPageIndex, lastPageIndex);
  }, [tasks, sortConfig, currentPage]);


  const handleSetDateToSelectedTasks = async () => {
    if (selectedDate && selectedTasks.length) {
      console.log('Setting dates for tasks', selectedTasks, 'to', selectedDate); // Debugging
      for (let taskId of selectedTasks) {
        try {
          const response = await fetch(`https://www.maintenancesmartcalendar.com/api/service/setTaskDate/${taskId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              setDate: selectedDate
            })
          });
          console.log('Response for task ID', taskId, ':', response.ok); // Debugging
          if (response.ok) {
            // Update the task in the tasks array with the new set date
            setTasks(currentTasks =>
              currentTasks.map(task => {
                if (task.taskID === taskId) {
                  return { ...task, 'Set Date': selectedDate };
                } else {
                  return task;
                }
              })
            );
          } else {
            console.error('Failed to set task date for task ID:', taskId);
          }
        } catch (error) {
          console.error('Error setting date:', error);
        }
      }
      // Consider adding state update logic here to reflect the new task dates in your component
      setSelectedTasks([]);
      setShowDatePicker(false);
    }
  };

  const handleTaskSelection = (taskId) => {
    setSelectedTasks(prevSelectedTasks =>
      prevSelectedTasks.includes(taskId)
        ? prevSelectedTasks.filter(id => id !== taskId)
        : [...prevSelectedTasks, taskId]
    );
  };

  useEffect(() => {
    if (selectedTasks.length === 0 || selectedTasks.length < tasksPerPage) {
      setSelectAll(false);
    } else if (selectedTasks.length === tasksPerPage) {
      setSelectAll(true);
    }
  }, [selectedTasks, tasksPerPage]);

  useEffect(() => {
    // Lock scrolling of the background content when the modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Unlock scrolling when the modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const pageCount = Math.ceil(tasks.length / tasksPerPage);
  const pages = [...Array(pageCount).keys()].map(num => num + 1);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="large-modal-content" onClick={e => e.stopPropagation()}>
        <button className="task-close-btn supervisor-btn large-image" onClick={onClose}>
          <img src='./img/close-b.svg' alt="Close B" />
        </button>
        <p className="modal-text-header">All Tasks</p>
        <div className="task-buttons">
          <button className="task-btn supervisor-btn large-image" title="Enable calendar" onClick={() => setShowDatePicker(!showDatePicker)}
            disabled={selectedTasks.length === 0}>
            <img src='./img/calendar-b.svg' alt="Calendar B" />
            <p className="task-btn-subheader">Assign</p>
          </button>
          <button className="task-btn supervisor-btn large-image" title="Delete tasks" onClick={handleDeleteSelectedTasks}>
            <img src='./img/delete-b.svg' alt="Delete B" />
            <p className="task-btn-subheader">Delete</p>
          </button>
        </div>

        <div className="data-picker-content">
          {showDatePicker && (
            <div className="date-picker">
              <input className="data-picker-input"
                title="Choose date"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
              <button className="task-confirm-btn supervisor-btn" title="Assign date to tasks"
                onClick={handleSetDateToSelectedTasks}>Confirm</button>
            </div>
          )}
        </div>
        <table className="task-modal-table">
          <thead>
            <tr>
              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Admin Number'"
                  onClick={() => requestSort('Admin Number')}>
                  Admin Number
                  <div className="font-size1">
                    {sortConfig && sortConfig.key === 'Admin Number' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Completion Status'"
                  onClick={() => requestSort('Completion Status')}>
                  Completion Status
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Completion Status' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Early Date'"
                  onClick={() => requestSort('Early Date')}>
                  Early Date
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Early Date' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Late Date'"
                  onClick={() => requestSort('Late Date')}>
                  Late Date
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Late Date' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Maintanence Item Description'"
                  onClick={() => requestSort('Maintanence Item Description')}>
                  Maintenance Item Description
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Maintanence Item Description' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Plan Date Maintanence Call'"
                  onClick={() => requestSort('Plan Date Maintanence Call')}>
                  Plan Date Maintenance Call
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Plan Date Maintanence Call' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Description of Technical Object'"
                  onClick={() => requestSort('Description of Technical Object')}>
                  Description of Technical Object
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Description of Technical Object' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Operational Status'"
                  onClick={() => requestSort('Operational Status')}>
                  Op. Status
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Operational Status' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Model Number'"
                  onClick={() => requestSort('Model Number')}>
                  Model Number
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Model Number' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Serial Number'"
                  onClick={() => requestSort('Serial Number')}>
                  Serial Number
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Serial Number' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Main Work Center'"
                  onClick={() => requestSort('Main Work Center')}>
                  Main Work Center
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Main Work Center' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>

              <th className="task-modal-th">
                <button type="button" className="task-sort-btn" title="Sort by 'Set Date'"
                  onClick={() => requestSort('Set Date')}>
                  Set Date
                  <div className="task-direction-arrow">
                    {sortConfig && sortConfig.key === 'Set Date' ? (
                      sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
                    ) : ' '}
                  </div>
                </button>
              </th>
              <th className="task-modal-th taskButtonTd">
                <input className = "taskCheck"
                  type="checkbox" 
                  title = "Select all tasks"
                  checked={selectAll} 
                  onChange={handleSelectAll} 
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedAndPagedTasks.map((task, index) => (
              <tr className="task-tr" key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                <td className="data-td task-modal-td">{task['Admin Number']}</td>
                <td className="data-td task-modal-td">{task['Completion Status']}</td>
                <td className="data-td task-modal-td">{task['Early Date']}</td>
                <td className="data-td task-modal-td">{task['Late Date']}</td>
                <td className="data-td task-modal-td">{task['Maintanence Item Description']}</td>
                <td className="data-td task-modal-td">{task['Plan Date Maintanence Call']}</td>
                <td className="data-td task-modal-td">{task['Description of Technical Object']}</td>
                <td className="data-td task-modal-td">{task['Operational Status']}</td>
                <td className="data-td task-modal-td">{task['Model Number']}</td>
                <td className="data-td task-modal-td">{task['Serial Number']}</td>
                <td className="data-td task-modal-td">{task['Main Work Center']}</td>
                <td className="data-td task-modal-td">{task['Set Date']}</td>
                <td className="data-td task-modal-td taskButtonTd">
                  <input className="taskCheck"
                    type="checkbox"
                    checked={selectedTasks.includes(task.taskID)}
                    onChange={() => handleTaskSelection(task.taskID)}
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="task-modal-pagination">
          {pages.map(page => (
            <button key={page}
              onClick={() => handlePageChange(page)}
              disabled={currentPage === page}
              className={`pagination-btn supervisor-btn font-size2 ${currentPage === page ? 'active' : ''}`}>
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
