// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.supervisor-highlight{
    color: black;
}

.supervisor-highlight:hover{
    background-color: black;
    color: #FFCC01;
}

.week-box-b {
    position: relative;
    display: flex;
    background: #FFCC01;
    border-radius: 1.2vw;
}

.altBorder-supervisor{
    border-color: #FFCC01 !important;
  }`, "",{"version":3,"sources":["webpack://./src/schedule.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gCAAgC;EAClC","sourcesContent":["\n\n.supervisor-highlight{\n    color: black;\n}\n\n.supervisor-highlight:hover{\n    background-color: black;\n    color: #FFCC01;\n}\n\n.week-box-b {\n    position: relative;\n    display: flex;\n    background: #FFCC01;\n    border-radius: 1.2vw;\n}\n\n.altBorder-supervisor{\n    border-color: #FFCC01 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
